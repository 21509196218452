/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/components/calendar/calendar.css";
@import "primeicons/primeicons.css";

// modal
.custom-modal {
    max-height: 90vh;
    max-width: 90vw;
}

.modal-title {
    background-color: #f8f8f8;
    padding: 0.5rem 1rem;
}

// p-radiobutton
.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #5453f6;
    background: #5453f6;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #5453f6;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #ccccf1;
    border-color: #5453f6;
}

// p-calendar
.p-inputtext:enabled:hover {
    border-color: #5453f6;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem #ccccf1;
}

// p-dropdown
.p-dropdown {
    width: 100%;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #5453f6;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #ccccf1;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #ccccf1;
    border-color: #5453f6;
}

// p-toast
.p-toast {
    width: 20rem !important;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 0.5rem;
    border-width: 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0;
}

// ngbTooltip
.tooltip-inner {
    background-color: black;
    color: white;
    border-radius: 0.358rem;
    opacity: 0.9;
    padding: 0.2rem 0.7rem;
    max-width: 200px;
    font-size: 12px;
}

// stars
.star-filled {
    color: rgb(253 224 71) !important;
}

.star-default {
    color: rgb(226 232 240);
}

.star-icon:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

// mat-menu-button
.mat-mdc-mini-fab {
    box-shadow: none !important;
}

// drag&Drop files & list
.drag-drop-files {
    padding: 2rem;
    text-align: center;
    border-style: dashed;
    border-width: 2px;
    position: relative;

    input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.files-list {
    margin-top: 1.5rem;

    .single-file {
        display: flex;
        padding: 0.5rem;
        justify-content: space-between;
        align-items: center;
        border-style: dashed;
        border-width: 2px;
        margin-bottom: 1rem;

        .delete {
            display: flex;
            margin-left: 0.5rem;
            cursor: pointer;
            align-items: center;
        }

        display: flex;
        flex-grow: 1;

        .name {
            font-weight: 500;
            color: #353f4a;
        }

        .size {
            font-size: 12px;
            font-weight: 500;
            color: #a4a4a4;
            margin-bottom: 0.25rem;
        }
    }
}
